import { useState } from "react";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";

const NavigationModal = () => {
    const [showModal, setShowModal] = useState(true);
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate('/');
        setShowModal(false);
        return;
    }
    return (
        <div className="modal-navigation">
            <Modal
                centered
                maskClosable={false}
                closable={false}
                footer={null}
                open={showModal}
            >
                <div className="modal-navigation-body flex flex-col items-center">
                    <h2 className="text-lg ">You are not authorized to view this page. Please click on Ok to navigate Home page</h2>
                    <Button className="mb-4 mt-12 w-[75px]" type='primary' onClick={navigateToHome}>Ok</Button>
                </div>
            </Modal>
        </div>
    )
}

export default NavigationModal