import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, message, Radio, Select, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useUsers } from '../../hooks/useUsers';
import { useSelector, useDispatch } from 'react-redux';
import { setLoader } from '../../features/loader/loaderSlice';

function RegisterForm() {
    const { userData } = useSelector(state => state.configAuth)
    const {
        refetchDataClients,
        dataClients,
        refetchDataRoles,
        dataRoles,
        handleCheckDuplicates,
        mutationAddUser,
        updateSuperUser
    } = useUsers();
    const [inputTypeUser, setTypeUser] = useState("");

    const [clients, setClients] = useState([]);
    const [roles, setRoles] = useState([]);
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        refetchDataClients();
        refetchDataRoles();
    }, [])

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        if (!name) {
            message.info("Please enter a client name and try again");
            return;
        }
        const existsClient = clients?.some(item => item.label === name);
        if (existsClient) {
            message.info("This client already exists, please add a different client")
        } else {
            const maxValue = clients?.reduce((max, item) => (item.id > max ? item.id : max), 0) + 1;
            const newClient = { id: maxValue, value: name, label: name };
            setClients([...clients, newClient]);
            setName('');
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    };


    const handleTypeUser = (e) => {
        const initialDataClient = dataClients.map(item => ({
            id: item.idclient,
            value: item.client,
            label: item.client
        }));
        setClients(initialDataClient);
        let initialDataRoles = [];
        if (e.target.value === "users_enovates") {
            initialDataRoles = dataRoles?.map(item => ({
                id: item.id,
                value: item.permissions,
                label: item.permissions,
            }));

        }
        else {
            initialDataRoles = dataRoles?.map(item => ({
                id: item.id,
                value: item.permissions,
                label: item.permissions,
                disabled: item.permissions === "Simple User" ? false : true
            }));
        }
        setRoles(initialDataRoles);
        setTypeUser(e.target.value);
        form.resetFields(['user_name', 'user_password', 'clients', 'roles'])
    };

    const isNewClientAdded = () => {
        const selectedClients = form.getFieldValue('clients')
        const newClients = !Array.isArray(selectedClients) ? [selectedClients] : selectedClients;
        const ArrayDataClients = dataClients?.map((item) => {
            return item?.client;
        });
        const newClient = newClients?.filter(
            (element) => !ArrayDataClients.includes(element)
        );
        return newClient?.length;
    }
    //Send Form Accion
    const handleSubmit = async (formData) => {
        const body = {
            user_name: formData?.user_name,
            user_password: formData?.user_password,
            clients: formData?.clients,
            roles: !Array.isArray(formData?.roles) ? [formData?.roles] : formData?.roles,
        }

        try {
            dispatch(setLoader(true))
            await handleCheckDuplicates(clients);
            await mutationAddUser.mutateAsync({ body: body, table: inputTypeUser, token: userData.token });
            if (isNewClientAdded()) {
                await updateSuperUser();
            }
        } catch (error) {
            message.error("Failed to register user. Please try again later!");
        } finally {
            form.resetFields();
            dispatch(setLoader(false))
        }
    };

    return (
        <div className="flex w-full h-full bg-slate-800 text-center justify-center items-center">
            <div className={`flex h-full w-full md:w-80p lg:w-60p font-sans bg-nc-white `} >
                <div className="flex w-full h-full items-start justify-start py-4 overflow-hidden">
                    <Form
                        form={form}
                        layout='vertical'
                        className={`form-registration flex flex-col w-full h-full overflow-y-auto text-center justify-center items-center pb-8 ${inputTypeUser !== "" ? 'pt-36' : 'pt-8'}`}
                        onFinish={handleSubmit}>
                        <div className="mb-6">
                            <h1 className="text-3xl text-center text-pc-blue ">AI INTEGRATION</h1>
                            <h2 className={"text-nc-gray text-center font-bold"}>
                                Register User
                            </h2>
                        </div>
                        <Form.Item name="userType" label="User Type" rules={[
                            {
                                required: true,
                                message: 'User Type is required',
                            }
                        ]}>
                            <Radio.Group onChange={handleTypeUser} value={inputTypeUser}>
                                <Radio value={"users_enovates"}>Master</Radio>
                                <Radio value={"users_externals"}>Simple User</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="User Name"
                            name="user_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'User Name is required',
                                }, {
                                    pattern: /^[a-zA-Z0-9-@._]*$/,
                                    message: 'Please enter a valid user name which contains only letters (both cases), numbers, hyphens, the "@" symbol, periods, or underscores',
                                }
                            ]}
                        >
                            <Input
                                type="email"
                                id="user_name"
                                placeholder="youremail@company.ltd"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name='user_password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Password is required',
                                }, {
                                    pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                                    message: 'Invalid Password, please check the password requirements',
                                }
                            ]}
                            tooltip={
                                <ul className="pl-4 list-disc">
                                    <li>
                                        The password must contain a minimum of 8
                                        characters.
                                    </li>
                                    <li>
                                        The password must contain at least one uppercase
                                        letter.
                                    </li>
                                    <li>
                                        The password must contain at least one special
                                        character.
                                    </li>
                                    <li>
                                        The password must contain at least one number.
                                    </li>
                                </ul>
                            }>
                            <Input.Password
                                id="password"
                                placeholder="Please enter password as per the requirements"
                            />
                        </Form.Item>
                        {inputTypeUser !== "" && <>
                            <Form.Item
                                label="Client"
                                name="clients"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Client is required',
                                    }
                                ]}>
                                <Select
                                    mode={inputTypeUser === "users_enovates" ? "multiple" : ''}
                                    placeholder={inputTypeUser === "users_enovates" ? "Select Clients" : "Select a Client"}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                <Input
                                                    placeholder="Please enter item"
                                                    ref={inputRef}
                                                    value={name}
                                                    onChange={onNameChange}
                                                    onKeyDown={(e) => e.stopPropagation()}
                                                />
                                                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                    Add item
                                                </Button>
                                            </Space>
                                        </>
                                    )}
                                    options={clients}
                                />
                            </Form.Item>
                            <Form.Item label="Role" name="roles" rules={[
                                {
                                    required: true,
                                    message: 'Role is required',
                                }
                            ]}>
                                <Select
                                    placeholder="Select a role"
                                    options={roles}
                                />
                            </Form.Item>
                        </>}
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button size='large' type='primary' className='mt-4' htmlType='submit'>
                                Register
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default RegisterForm