import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import notFound from '../../assets/image/404NotFound.png'

const NotFound = () => {
    const navigate = useNavigate()
    return (
        <div className="w-screen h-screen flex items-center">
            <div className="w-full text-center ">
                <div className="flex flex-col items-center justify-center  lg:justify-between">
                    <img className='m-auto w-[150px]' src={notFound} alt='Not Found' />
                    <h1 className="font-sans font-bold text-5xl max-w-2xl lg:block m-auto">
                        404
                    </h1>
                    <h2>
                        We couldn't find the page you were looking for...
                    </h2>
                    <Button
                        className="my-4"
                        type='link'
                        shape="round"
                        iconPosition="end"
                        icon={<ArrowRightOutlined />}
                        onClick={() => navigate('/')}
                    >
                        Back to the homepage
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NotFound;
