import { createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { loginUser } from "../../services/loginAPI";

export const setAuth = (auth) => (dispatch) => {
    sessionStorage.setItem('auth', auth);
    dispatch({
        type: 'configAuth/setAuth',
        payload: auth
    });
};

export const setUserData = (userData) => (dispatch) => {
    sessionStorage.setItem("userData", JSON.stringify(userData));
    dispatch({
        type: 'configAuth/setUserData',
        payload: userData
    });
};


export const signIn = createAsyncThunk(
    'configAuth/signIn',
    async (userCredentials, { rejectWithValue }) => {
        try {
            const responseLogin = await loginUser(userCredentials);
            if (responseLogin.token === null || responseLogin['token'] === undefined) {
                return rejectWithValue(responseLogin.message);
            }
            const loggedInUserId = jwtDecode(responseLogin?.token)?.id;
            const userData = {
                user_name: (userCredentials.user_name).split('@')[0],
                user_email: userCredentials.user_name,
                role: responseLogin.role,
                token: responseLogin.token,
                table: responseLogin.table,
                userId: loggedInUserId
            };
            sessionStorage.setItem('auth', true);
            sessionStorage.setItem("userData", JSON.stringify(userData));
            return userData
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
