import { configureStore } from "@reduxjs/toolkit";
import { configAuthReducer } from "../auth/feature/configAuth/configAuthSlice";
import { loaderReducer } from "../features/loader/loaderSlice";

const store = configureStore({
    reducer: {
        configAuth: configAuthReducer,
        loader: loaderReducer
    }
})

export { store }