import React from 'react'
import { useSelector } from 'react-redux';
import RegisterForm from '../../components/Users_Control/RegisterForm';
import { ROLES_TO_REGISTER } from '../../types/type_roles';
import NavigationModal from '../../components/modals/NavigationModal';

function RegisterUser() {
  const { userData } = useSelector(state => state.configAuth);
  const userRoles = !Array.isArray(userData?.role) ? [userData?.role] : userData.role;
  const isEligibleToRegister = userRoles.some(role => ROLES_TO_REGISTER.includes(role));

  if (!isEligibleToRegister) {
    return <NavigationModal />
  }

  return (
    <div className='flex flex-row h-full w-full bg-slate-50 lg:bg-white overflow-y-auto'>
      <div className='flex flex-col w-full'>
        <div className='flex h-full lg:bg-slate-50'>
          <div className='flex w-full h-full bg-slate-500'>
            <RegisterForm ></RegisterForm>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterUser