import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

function ProtectedRoute({ isAllowed, children, redirecTo = "/login" }) {
  const { auth, userData } = useSelector(state => state.configAuth)
  var roleUser = [];
  var validateRoleUser = false;

  const SelectRoles = (condition) => {
    switch (condition) {
      case 'External':
        return ["Simple User"];
      case 'Internal':
        return ["Admin", "User Enovate", "Moderator"];
      case 'Bound':
        return ["Admin", "User Enovate", "Moderator", "Simple User"];
      default:
        return 'Dont Rol';
    }
  };

  const Roles = SelectRoles(isAllowed);
  if (roleUser !== null) {
    roleUser = userData?.role;
    // Este proceso es neceario ya que en la base de datos el usuario puede tener muchos roles o solo uno, por lo tanto puede llegar como arreglo o como string
    if (!Array.isArray(roleUser)) {
      roleUser = [roleUser];
    }
    validateRoleUser = roleUser.every((element) => Roles.includes(element));
  }

  if (auth) {
    return children ? children : <Outlet />;
  }

  // if (auth && !validateRoleUser) {
  //   alert("I don't have permission fot this view");
  //   if (
  //     roleUser.includes("Admin") ||
  //     roleUser.includes("User Enovate") ||
  //     roleUser.includes("Moderator")
  //   ) {
  //     return <Navigate to={"/admin"} />;
  //   } else {
  //     return <Navigate to={"/"} />;
  //   }
  // }

  return <Navigate to={redirecTo} />;
}

export default ProtectedRoute