import React from 'react'
import { ReactComponent as SidebarLogo } from '../assets/EnovateLogo.svg';
import { Bag } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import ReportLinks from '../components/ReportLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import UserControl from './UserControl';

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userData } = useSelector(state => state.configAuth);
    let enableConfigUser = [];
    // Este proceso es neceario ya que en la base de datos el usuario puede tener muchos roles o solo uno, por lo tanto puede llegar como arreglo o como string
    if (!Array.isArray(userData?.role)) {
        enableConfigUser = [userData?.role];
    }
    else {
        enableConfigUser = userData?.role;
    }

    const naviateToMessages = () => {
        if (location.pathname !== '/messages') {
            navigate('/messages')
        }
    }

    return (
        <div className='sidebar hidden lg:flex flex-col  lg:w-20p p-6 border-r'>
            <div className='logo flex items-center px-1 py-3 mb-8'>
                <SidebarLogo className='scale-75 cursor-pointer' onClick={() => navigate('/')} />
            </div>
            <div className='flex-1'>
                <div className='link-generate-report flex flex-row items-center pb-6 border-b'>
                    <Bag className='mr-2' />
                    <span>Create general report</span>
                </div>
                <div className='report-links'>
                    <ReportLinks />
                </div>
                <div className='chat-history-nav-section my-4'>
                    <Button shape="round" size='large' className='border-black' onClick={naviateToMessages}>
                        Chat History
                    </Button>
                </div>
            </div>
            {!enableConfigUser.includes("Simple User") &&
                <div className='user-control-links flex'>
                    <UserControl />
                </div>
            }
        </div>
    )
}

export default Sidebar