import { useContext } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signout } from '../auth/feature/configAuth/configAuthSlice';
import { useDispatch } from 'react-redux';
import { ChatContext } from '../context/chat/ChatContext';

const UserProfile = () => {
    const { userData } = useSelector(state => state.configAuth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { messages } = useContext(ChatContext);
    const loggedInUserId = userData?.userId;

    const saveMessages = () => {
        const updatedMessagesForStreaming = messages?.map(message => ({ ...message, streamingMessage: false }));
        if (loggedInUserId) {
            const savedMessages = JSON.parse(localStorage.getItem('messages'));
            let updatedMessages = {};
            if (Array.isArray(savedMessages)) {
                updatedMessages = { [loggedInUserId]: updatedMessagesForStreaming }
            }
            else {
                updatedMessages = { ...savedMessages, [loggedInUserId]: updatedMessagesForStreaming }
            }
            localStorage.setItem('messages', JSON.stringify(updatedMessages))
        } else {
            localStorage.setItem('messages', JSON.stringify(updatedMessagesForStreaming))
        }
    }

    const handleMenuClick = ({ key }) => {
        if (key === 'signout') {
            sessionStorage.clear();
            saveMessages();
            dispatch(signout());
            navigate('/login', { replace: true })
        }
    }
    return (
        <div className='user-profile flex flex-row'>
            <Menu
                mode='inline'
                onClick={handleMenuClick}
                items={[{
                    label: <div className='user-info-container flex flex-row items-center p-1'>
                        <Avatar shape="square" size={40} icon={<UserOutlined />} />
                        <div className='hidden lg:flex flex-col p-1 leading-none'>
                            <h3 className='font-bold mb-1'>
                                {userData?.user_name || 'New User'}
                            </h3>
                            <span>
                                {userData?.role || 'Operator Co.'}
                            </span>
                        </div>
                    </div>,
                    children: [
                        { label: 'Settings', key: 'settings' },
                        { label: 'Signout', key: 'signout' }
                    ]
                }]}>
            </Menu>
        </div>
    )
}

export default UserProfile