import { useState, useEffect, useRef } from 'react';
import { Form, Modal, Input, Select, Button, Divider, Space, message } from 'antd';
import { EditTwoTone, PlusOutlined } from '@ant-design/icons';
import { useUsers } from '../../hooks/useUsers';
import { USER_TYPES } from '../../types/types_user';
import { putUsersUpdate } from '../../services/api/usersAPI';
import { TABLES } from '../../types/types_table';
import useCrud from '../../hooks/useCrud';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../features/loader/loaderSlice';

const delay = ms => new Promise(res => setTimeout(res, ms));

const EditUserModal = ({ open = false,
    user = {},
    userType = '',
    table = '',
    onEditConfirm = () => { },
    onCancel = () => { },
    updateUser = () => { } }) => {

    const {
        refetchDataClients,
        dataClients,
        handleCheckDuplicates,
        updateSuperUser
    } = useUsers();
    const { refetchInternalUsersData,
        refetchExternalUsersData, } = useCrud()
    const [clients, setClients] = useState([]);
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const isNewClientAdded = () => {
        const newClients = !Array.isArray(user.clients) ? [user.clients] : user.clients;
        const ArrayDataClients = dataClients?.map((item) => {
            return item?.client;
        });
        const newClient = newClients?.filter(
            (element) => !ArrayDataClients.includes(element)
        );
        return newClient?.length;
    }

    const handleSubmit = async () => {
        try {
            if (!Array.isArray(user?.clients) ? user.clients !== "" : user.clients.length) {
                const userToken = JSON.parse(sessionStorage.getItem("userData"))?.token;
                dispatch(setLoader(true))
                await handleCheckDuplicates(clients);
                await putUsersUpdate(user, user?.iduser, table, userToken);
                if (isNewClientAdded()) {
                    await updateSuperUser();
                }
                await refetchDataClients();
                if (table === TABLES.ENOVATE) {
                    await refetchInternalUsersData();
                }
                if (table === TABLES.EXTERNAL) {
                    await refetchExternalUsersData();
                }
                await delay(1000)
                onEditConfirm();
                message.success(`User ${user.user_name} updated successfully`);
            }
            else {
                message.info("Incomplete fields, please validate the information entered");
            }

        } catch (err) {
            console.error(err);
            message.error(err?.message)
        } finally {
            dispatch(setLoader(false))
        }
    }
    const addItem = (e) => {
        e.preventDefault();
        if (!name) {
            message.info("Please enter a client name and try again");
            return;
        }
        const existsClient = clients?.some(item => item.label === name);
        if (existsClient) {
            message.info("This client already exists, please add a different client");
            return;
        } else {
            const maxValue = clients?.reduce((max, item) => (item.id > max ? item.id : max), 0) + 1;
            const newClient = { id: maxValue, value: name, label: name };
            setClients([...clients, newClient]);
            setName('');
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);
        }
    };

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const handleChangeSelect = (value) => {
        const clientNames = !Array.isArray(value) ? [value] : value;
        const clientIds = clients?.filter(client => clientNames?.includes(client?.value))?.map(client => client?.id);
        const clientIdData = !Array.isArray(value) ? clientIds?.[0] : clientIds;
        updateUser({ ...user, clients: value, user_clientId: clientIdData })
    }

    useEffect(() => {
        refetchDataClients();
    }, [])

    useEffect(() => {
        if (dataClients?.length) {
            const initialDataClient = dataClients?.map(item => ({
                id: item.idclient,
                value: item.client,
                label: item.client
            }));
            setClients(initialDataClient);
        } else {
            setClients([])
        }
    }, [dataClients])


    return (
        <div className='edit-user-modal'>
            <Modal
                title={(
                    <div className='edit-user-modal-header flex justify-center text-xl'>
                        <EditTwoTone twoToneColor='#1677ff' />
                        <h2 className='mx-2'>Edit User</h2>
                    </div>
                )}
                centered
                maskClosable={false}
                closable={false}
                footer={null}
                open={open}
                onCancel={onCancel}>
                <div className='edit-user-modal-body py-6 flex flex-col items-center text-lg'>
                    <Form
                        layout='vertical'
                        className={`form-edit-user flex flex-col w-full h-full overflow-y-auto text-center justify-center items-center`}
                        onFinish={handleSubmit}>
                        <Form.Item label="User Type">
                            <Input
                                id="userType"
                                name="userType"
                                value={userType}
                                disabled />
                        </Form.Item>
                        <Form.Item label="User Name">
                            <Input
                                id="user_name"
                                name="user_name"
                                value={user?.user_name}
                                disabled />
                        </Form.Item>
                        <Form.Item label="Client" required>
                            <Select
                                mode={userType === USER_TYPES.MASTER ? "multiple" : ''}
                                name="clients"
                                value={user.clients}
                                onChange={(value) => handleChangeSelect(value)}
                                placeholder={userType === USER_TYPES.MASTER ? "Select Clients" : "Select a Client"}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider
                                            style={{
                                                margin: '8px 0',
                                            }}
                                        />
                                        <Space
                                            style={{
                                                padding: '0 8px 4px',
                                            }}
                                        >
                                            <Input
                                                placeholder="Please enter item"
                                                ref={inputRef}
                                                value={name}
                                                onChange={onNameChange}
                                                onKeyDown={(e) => e.stopPropagation()}
                                            />
                                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                Add item
                                            </Button>
                                        </Space>
                                    </>
                                )}
                                options={clients}
                            />
                        </Form.Item>
                        {userType === USER_TYPES.MASTER &&
                            <Form.Item label="Role">
                                <Input
                                    id="roles_name"
                                    name="roles_name"
                                    value={user?.roles_name}
                                    disabled />
                            </Form.Item>
                        }
                        <div className='form-edit-user-footer flex justify-end w-90p md:w-80p lg:w-60p mt-6'>
                            <Form.Item className='mx-4'>
                                <Button size='large' onClick={onCancel}>
                                    Cancel
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button size='large' htmlType='submit' type='primary'>
                                    Update
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default EditUserModal