import EnovateLogo from '../assets/icon/logo-enovate.png';
import B4ELogo from '../assets/icon/logo-b4e.png';
import RegisterLogo from '../assets/icon/registerUser.svg';
import InternalLogo from '../assets/icon/internalCrud.svg';
import ExtneralLogo from '../assets/icon/externalCrud.svg';
import { DATA_ROLES } from '../types/type_roles';

export const navbarLinks = [{
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'Drilling DO',
    navBarLinkAltText: 'Logo for ADA AI Drilling DO',
    navBarLinkUrl: 'https://drilloptim-rollout.enovate.app/'
}, {
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'Drilling BI',
    navBarLinkAltText: 'Logo for ADA AI Drilling BI',
    navBarLinkUrl: 'https://drillbi-rollout.enovate.app'
}, {
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'New Energy',
    navBarLinkAltText: 'Logo for ADA AI New Energy',
    navBarLinkUrl: 'https://geothermal-dev.enovate.app/'
}, {
    navBarLinkLogo: EnovateLogo,
    navBarLinkBrandName: 'ADA AI',
    navBarLinkText: 'Production',
    navBarLinkAltText: 'Logo for ADA AI Production',
    navBarLinkUrl: 'https://prodanalytics-rollout.enovate.app'
}, {
    navBarLinkLogo: B4ELogo,
    navBarLinkText: 'B4E Carbon',
    navBarLinkAltText: 'Logo for B4E Carbon',
    navBarLinkUrl: 'https://sbx.carbon.blockchainforenergy.net/'
}
]

export const userControlLinks = [{
    userControlLinkLogo: RegisterLogo,
    userControlLinkBrandName: 'Register New Users',
    userControlLinkText: '/register-users',
    userControlLinkAltText: 'Logo for ADA AI Drilling',
    applicableRoles: [DATA_ROLES.ADMIN, DATA_ROLES.MODERATOR, DATA_ROLES.USER_ENOVATE]
},
{
    userControlLinkLogo: InternalLogo,
    userControlLinkBrandName: 'Control External Users',
    userControlLinkText: '/crud/external-users',
    userControlLinkAltText: 'Logo for Extnernal Users',
    applicableRoles: [DATA_ROLES.ADMIN]
}, {
    userControlLinkLogo: ExtneralLogo,
    userControlLinkBrandName: 'Control Enovate Users',
    userControlLinkText: '/crud/internal-users',
    userControlLinkAltText: 'Logo for Internal Users',
    applicableRoles: [DATA_ROLES.ADMIN]
}
]