import { Spin } from 'antd';
import EnovateLogo from '../assets/icon/logo-enovate-black.png';
import { useSelector } from 'react-redux';


const Loader = () => {
    const { isLoading } = useSelector(state => state.loader)
    const spinningIndicator = <img src={EnovateLogo} height={100} width={100} alt='Spinner' className='loader-image animate-spin' />
    return (
        <Spin rootClassName='enovate-loader' className='loader' spinning={isLoading} indicator={spinningIndicator} fullscreen />
    )
}

export default Loader;