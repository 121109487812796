import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Layout from '../layout/Layout';
import Dashboard from '../pages/Dashboard';
import Messages from '../pages/Messages';
import { ChatProvider } from '../context/chat/ChatProvider';
import ProtectedRoute from '../components/ProtectedRoute';
import Login from '../auth/pages/Login';
import NotFound from '../components/elements/NotFound';
import RegisterUser from '../pages/Users_Control/RegisterUser';
import CrudExternal from '../pages/Users_Control/CrudExternal';
import CrudInternal from '../pages/Users_Control/CrudInternal';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient()

const AppRoutes = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ChatProvider>
                <Routes>
                    <Route element={<ProtectedRoute isAllowed="External" />}>
                        <Route path='/' element={<Layout />}>
                            <Route index element={<Dashboard />} />
                            <Route path='/messages' element={<Messages />} />
                            <Route path="/register-users" element={<RegisterUser />} />
                            <Route path="/crud/external-users" element={<CrudExternal />} />
                            <Route path="/crud/internal-users" element={<CrudInternal />} />
                        </Route>
                    </Route>
                    <Route path='/login' element={<Login />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </ChatProvider>
        </QueryClientProvider>
    )
}

export default AppRoutes