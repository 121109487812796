import { Button, Tooltip } from 'antd';
import React from 'react';
import { userControlLinks } from '../constants/navbarData';
import SvgIcon from '../components/SvgIcon';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';


function UserControl() {
    const { userData } = useSelector(state => state.configAuth);
    let enableConfigUser = [];
    // Este proceso es neceario ya que en la base de datos el usuario puede tener muchos roles o solo uno, por lo tanto puede llegar como arreglo o como string
    if (!Array.isArray(userData?.role)) {
        enableConfigUser = [userData?.role];
    }
    else {
        enableConfigUser = userData?.role;
    }

    const userControlFilteredLinks = userControlLinks.filter(cLink => cLink.applicableRoles.some(role => enableConfigUser.includes(role)))
    return (
        <div className='usercontrol-links-container flex flex-col flex-wrap lg:flex-row'>
            {userControlFilteredLinks.map(userControlLink => (
                <NavLink
                    key={userControlLink.userControlLinkBrandName}
                    to={userControlLink.userControlLinkText}
                >
                    <Tooltip placement='topLeft' title={userControlLink.userControlLinkBrandName}>
                        <Button
                            key={userControlLink.userControlLinkBrandName}
                            className='lg:mx-1 my-3 lg:my-0 p-1'
                            size='middle'
                            shape='round'
                            icon={<SvgIcon src={userControlLink.userControlLinkLogo} alt={userControlLink.userControlLinkAltText} height={24} width={24} className='max-w-none' />}
                        >
                            <span className='user-control-name'>
                                {userControlLink.userControlLinkBrandName}
                            </span>
                        </Button>
                    </Tooltip>
                </NavLink>
            ))}
        </div>
    )
}

export default UserControl