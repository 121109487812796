import { Alert, Tooltip, Button } from 'antd';
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as EnovateLogo } from '../../assets/EnovateLogoWhite.svg';
import { reCAPTCHAConfig } from '../../config/config';

import { signIn } from '../../auth/feature/configAuth/configAuthAction';
import { STATUS_TYPE_ATUH } from '../../types/type_auth';
import { DATA_ROLES } from '../../types/type_roles';

export const AuthForm = () => {

    //Redux
    const { loading, error } = useSelector((state) => state.configAuth);
    const dispatch = useDispatch();

    const [captchaValide, changeCaptchaValide] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [user_data, setUser_data] = useState({ user_name: "", user_password: "" });
    const [messageAlert, setMessageAlert] = useState("");
    const navigate = useNavigate();

    const captcha = useRef(null);

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        let newData = { ...user_data, [name]: value };
        setUser_data(newData);
    };
    const [shouldReload, setShouldReload] = useState(false);

    useEffect(() => {

        const captchaDiv = document.querySelector(".recaptcha > div");
        setTimeout(() => {
            if (captchaDiv && captchaDiv.childElementCount === 0) {
                setShouldReload(true);
            }
        }, 1000);

    }, []);

    useEffect(() => {
        if (shouldReload) {
            window.location.reload();
        }
    }, [shouldReload]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessageAlert("");
        if (user_data.user_password && user_data.user_name) {
            if (!event.target.checkValidity() || !captcha.current?.getValue()) {
                changeCaptchaValide(false);
            }
            else {
                if (captcha.current?.getValue() && event.target.checkValidity()) {
                    let credentials = {
                        user_name: user_data.user_name,
                        user_password: user_data.user_password
                    }
                    dispatch(signIn(credentials)).then((result) => {
                        if (result.payload !== STATUS_TYPE_ATUH.message) {
                            navigate('/');
                            return null;
                        }
                    })
                }
            }
        }
        else {
            setMessageAlert("Please enter your User Name and Password");
        }
    };

    function onChangeCaptcha() {
        if (captcha.current?.getValue()) {
            changeCaptchaValide(true);
        } else {
            changeCaptchaValide(false);
        }
    }

    return (
        <div className="w-full h-full">
            <form
                onSubmit={handleSubmit}
                className='flex flex-col md:w-96 h-full shandow-md  md:backdrop-blur md:backdrop-saturate-200 md:bg-white/30  p-8  text-center justify-center'
            >
                <div className="mb-4">
                    <h1 style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '60px', fontFamily: 'Inter, sans-serif' }} className=" text-center justify-center  text-white">ADA AI Agent</h1>
                </div>
                <div className="mb-4 text-left">
                    <label style={{ fontSize: '15px', lineHeight: '20px', fontFamily: 'Inter, sans-serif' }} htmlFor="text" className="text-nc-white">
                        User name
                    </label>
                    <input
                        type="email"
                        name="user_name"
                        id="user_name"
                        value={user_data.user_name}
                        onChange={handleInputChange}
                        className="shadow text-nc-white appearance-none border-white border-[1px] border-opacity-[0.4] rounded w-full py-2 px-3 leading-tight focus:outline-none pr-10 bg-[#ffffff00]"
                    />
                </div>
                <div className="mb-4 text-left">
                    <label htmlFor="password" style={{ fontSize: '15px', lineHeight: '20px', fontFamily: 'Inter, sans-serif' }} className="block text-nc-white">
                        Password
                    </label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            name="user_password"
                            id="user_password"
                            value={user_data.user_password}
                            onChange={handleInputChange}
                            className="shadow text-nc-white appearance-none border-white border-[1px] border-opacity-[0.4] rounded w-full py-2 px-3 leading-tight focus:outline-none pr-10 bg-[#ffffff00]"
                        />
                        <span
                            className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? (
                                <Tooltip title={`Hide password`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                </Tooltip>
                            ) : (
                                <Tooltip title={`Show password`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </Tooltip>
                            )}
                        </span>
                    </div>
                </div>
                <div className="recaptcha recaptcha-container mx-auto">
                    <ReCAPTCHA
                        ref={captcha}
                        sitekey={reCAPTCHAConfig.RECAPTCHA_SITE_KEY}
                        onChange={onChangeCaptcha}
                        hl="en"
                    />
                </div>
                {captchaValide === false && (
                    <div className="my-3"><Alert message="Please check the captcha validation" type="error" showIcon /></div>
                )}
                {messageAlert.trim() !== "" &&
                    <div className="my-3"><Alert message={messageAlert} type="error" showIcon /></div>
                }
                {error !== null &&
                    <div className="my-3"><Alert message={"Incorrect user or password, please verify the data"} type="error" showIcon /></div>
                }

                <div className="m-4 text-white flex justify-center">
                    <Button className="px-12 py-3 text-white bg-black border-none text-sm rounded-md" loading={loading} htmlType="submit">
                        {!loading ?
                            "Submit"
                            :
                            "Signing in.."
                        }
                    </Button>
                </div>
                <p className="text-white text-sm font-sans text-left my-1">Forgot password? <NavLink to="/forgot-password" className="font-bold hover:text-pc-blue font-sans">Recover</NavLink></p>
                <div className="w-full h-20 mt-auto justify-normal items-center flex flex-col">
                    <EnovateLogo height={40} />
                </div>
            </form>
        </div>
    );
};
