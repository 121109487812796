import React from 'react';
import { Drawer, Button } from 'antd';
import { ReactComponent as SidebarLogo } from '../assets/EnovateLogo.svg';
import { Bag } from 'react-bootstrap-icons';
import ReportLinks from '../components/ReportLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarLinks from './NavbarLinks';
import UserControl from './UserControl';

function MobileNavigationDrawer({ onClose, open }) {
    const navigate = useNavigate();
    const location = useLocation();

    const naviateToMessages = () => {
        if (location.pathname !== '/messages') {
            navigate('/messages')
        }
    }
    return (
        <div className='mobile-navigation-drawer-container'>
            <Drawer rootClassName='mobile-navigation-drawer' onClose={() => onClose(false)} open={open} placement="left">
                <div className='logo w-[300px] -translate-x-8 lg:md:transform-none lg:px-1 py-3 mb-2 lg:mb-8'>
                    <SidebarLogo className='scale-75 cursor-pointer' onClick={() => navigate('/')} />
                </div>
                <div>
                    <NavbarLinks />
                </div>
                <div className='link-generate-report flex flex-row items-center mt-4 lg:mt-0 pb-3 lg:pb-6 font-medium border-b'>
                    <Bag className='mr-2' />
                    <span>Create general report</span>
                </div>
                <div className='report-links'>
                    <ReportLinks />
                </div>
                <div className='chat-history-nav-section my-4'>
                    <Button shape="round" size='large' className='border-black' onClick={naviateToMessages}>
                        Chat History
                    </Button>
                </div>
                <div className='mt-4 flex flex-col flex-wrap'>
                    <UserControl />
                </div>
            </Drawer>
        </div>
    )
}

export default MobileNavigationDrawer