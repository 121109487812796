import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import { useBrowserCloseOrReload } from '../hooks/useBrowserCloseOrReload';

const Layout = () => {
    useBrowserCloseOrReload();
    return (
        <div className='flex flex-row h-screen w-screen bg-slate-50 lg:bg-white'>
            <Sidebar />
            <div className='flex flex-col w-full h-screen flex flex-col flex-1 justify-end overflow-hidden lg:bg-slate-50'>
                <Navbar />
                <div className='main-container relative  flex-1 overflow-y-auto pb-4 lg:pt-4'>
                    <div className='outlet-container h-full overflow-hidden'>
                        <Outlet />
                    </div>
                </div>
                <div className='footer-container w-full'>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Layout