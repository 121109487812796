import { useMutation, useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getUsersClients, getUsersRoles, postClients, postUsersAdd, getUsersData, getUserDataForName, putUsersUpdate } from '../services/api/usersAPI'
import { message } from 'antd'

export const useUsers = () => {
  const { userData } = useSelector(state => state.configAuth)
  const { data: dataClients, isPending: loadDataClients, error: errorDataClients, refetch: refetchDataClients } = useQuery({
    queryKey: ['list_client'],
    retry: 2,
    enabled: false,
    queryFn: () => getUsersClients(userData.token),
  })
  const { data: dataRoles, isPending: loadDataRoles, error: errorDataRoles, refetch: refetchDataRoles } = useQuery({
    queryKey: ['list_roles'],
    retry: 2,
    enabled: false,
    queryFn: () => getUsersRoles(userData.token),
  })

  const mutationAddUser = useMutation({
    mutationFn: postUsersAdd,
    onSuccess: (data) => {
      message.success("User has been successfully registered");
    },
  })

  const sequentialCreate = async (newClient) => {
    // Iterar sobre newClient secuencialmente
    for (const elemento of newClient) {
      try {
        await postClients({ client: elemento }, userData.token);
      } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        throw error;
      }
    }
  }

  async function handleCheckDuplicates(selectedClients) {
    try {
      const validateClients = selectedClients.map(item => item.value);
      const uniqueArrayvalidateClients = [...new Set(validateClients)];
      const dataClients = await getUsersClients(userData.token);
      const ArrayDataClients = dataClients.map((item) => {
        return item.client;
      });

      const uniqueArrayClients = [...new Set(ArrayDataClients)];
      const newClient = uniqueArrayvalidateClients.filter(
        (element) => !uniqueArrayClients.includes(element)
      );
      const oldClient = uniqueArrayvalidateClients.filter(
        (element) => uniqueArrayClients.indexOf(element) !== -1
      );
      //Si se creea un nuevo cliente asocia los datos de ese cliente con los proyectos y plataformas que se seleccionen así estas sean nuevas
      if (newClient?.length > 0) {
        return await sequentialCreate(newClient)
      } else {
        return Promise.resolve()
      }
    }
    catch (err) {
      throw err;
    }
  }

  const updateSuperUser = async () => {
    try {
      const data_query_roles = await getUsersData(userData.token, "users_enovates");
      const filteredAdmin = data_query_roles?.filter((item) =>
        item?.roles_name?.includes("Admin")
      );
      const dataClients = await getUsersClients(userData.token);
      filteredAdmin?.forEach(async (item) => {
        const dataSuperUser = await getUserDataForName(userData.token, item?.user_name, "users_enovates");
        const ArrayClients = dataClients?.map((item) => {
          return item?.idclient;
        });
        const ArrayClientsUnique = [...new Set(ArrayClients)];

        const updateDataSuperUser = {
          user_name: dataSuperUser.user_name,
          user_clientId: ArrayClientsUnique,
        };

        await putUsersUpdate(updateDataSuperUser, dataSuperUser.iduser, "users_enovates", userData.token);
      });
    } catch (err) {
      throw new Error("Failed to update super users")
    }
  }

  return {
    handleCheckDuplicates,
    refetchDataClients,
    dataClients,
    loadDataClients,
    errorDataClients,
    refetchDataRoles,
    dataRoles,
    loadDataRoles,
    errorDataRoles,
    mutationAddUser,
    updateSuperUser
  }
}
