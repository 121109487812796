import { useEffect } from 'react';
import { Input, Table } from 'antd';
import { DeleteTwoTone, EditTwoTone, SearchOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import externalUserTableColumns from '../../utils/table/externalUserTableColumns';
import DeleteUserModal from '../../components/modals/DeleteUserModal';
import EditUserModal from '../../components/modals/EditUserModal';
import { MODAL_TYPES } from '../../types/types_modal';
import { USER_TYPES } from '../../types/types_user';
import useCrud from '../../hooks/useCrud';
import { TABLES } from '../../types/types_table';
import { ROLES_TO_UPDATE } from '../../types/type_roles';
import NavigationModal from '../../components/modals/NavigationModal';

function CrudExternal() {
  const { userData } = useSelector(state => state.configAuth);
  const userRoles = !Array.isArray(userData?.role) ? [userData?.role] : userData.role;
  const isEligibleToUpdateUser = userRoles?.some?.(role => ROLES_TO_UPDATE.includes(role));

  const { externalUsersData,
    setEnableExternalUsersQuery,
    searchTerm,
    setSearchTerm,
    selectedUser,
    handleEdit,
    handleDelete,
    setSelectedUser,
    onDeleteConfirm,
    onCancel,
    onEditConfirm,
    modalType } = useCrud();

  useEffect(() => {
    setEnableExternalUsersQuery(true)
  }, [])

  const getFilteredUserData = () => {
    return externalUsersData?.filter?.(user => (user?.user_name?.toLowerCase?.()?.includes(searchTerm?.toLowerCase()) ||
      user?.clients?.toString().toLowerCase().includes(searchTerm?.toLowerCase()) ||
      user?.iduser?.toString().toLowerCase().includes(searchTerm?.toLowerCase())))
  }
  const userInfo = searchTerm ? getFilteredUserData() : externalUsersData;
  const columns = [
    ...externalUserTableColumns,
    {
      title: 'Edit',
      dataIndex: 'edit',
      render: (_, record) => {
        return <EditTwoTone onClick={() => handleEdit(record)} />
      }
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      render: (_, record) => {
        return <DeleteTwoTone twoToneColor="red" onClick={() => handleDelete(record)} />
      }
    },
  ]

  if (!isEligibleToUpdateUser) {
    return <NavigationModal />
  }

  return (
    <div className='h-full w-full bg-white p-6 overflow-y-auto'>
      <div className='user-search my-4'>
        <Input
          placeholder="Search..."
          prefix={<SearchOutlined />}
          size='large'
          className='w-80p md:w-60p lg:w-40p'
          value={searchTerm}
          onChange={
            (e) => setSearchTerm(e.target.value)
          } />
      </div>
      <div className='user-table'>
        <Table
          columns={columns}
          dataSource={userInfo}
          rowKey={record => record?.iduser}
          pagination={{ pageSize: 5 }}
          bordered />
      </div>
      {modalType === MODAL_TYPES.DELETE && (
        <DeleteUserModal
          open
          user={selectedUser}
          onDeleteConfirm={() => onDeleteConfirm(TABLES.EXTERNAL)}
          onCancel={onCancel} />)}
      {modalType === MODAL_TYPES.EDIT && (
        <EditUserModal
          open
          user={selectedUser}
          updateUser={setSelectedUser}
          userType={USER_TYPES.SIMPLE}
          onCancel={onCancel}
          onEditConfirm={onEditConfirm}
          table={TABLES.EXTERNAL}
        />)}
    </div>
  )
}

export default CrudExternal