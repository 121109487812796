import { config } from '../../config/config';
const BASE_URL = config.BASE_URL_ACCESS_CONTROL;

export const getUsersClients = async (token) => {
  if (!token) {
    console.warn("Missing parameters, please ensure all parameters are provided.");
    return;
  }

  try {
    const response = await fetch(`${BASE_URL}/Clients`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem with customer");
  }
};

export const getUsersClient = async (token, idClient) => {
  if (!token || !idClient) {
    console.warn("Missing parameters, please ensure all parameters are provided.");
    return;
  }

  try {
    const response = await fetch(`${BASE_URL}/Clients/${idClient}`, {
      method: "GET",
      headers: { "x-access-Token": token },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem with customer");
  }
};


export const getUsersDataClient = async (token, options) => {
  if (!token || !options) {
    console.warn("Missing parameters, please ensure all parameters are provided.");
    return;
  }

  try {
    const response = await fetch(`${BASE_URL}/DataClients/Clients/${options}`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem with customer data");
  }
};

export const getUsersRoles = async (token) => {
  if (!token) {
    console.warn("Missing parameters, please ensure all parameters are provided.");
    return;
  }

  try {
    const response = await fetch(`${BASE_URL}/Roles`, {
      method: "GET",
      headers: { "x-access-token": token },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem with the obtaining of roles");
  }
};

export const postUsersAdd = async (dataJson) => {
  const body = dataJson.body;
  const table = dataJson.table;
  const token = dataJson.token;
  if (!body || !table || !token) {
    console.warn("Missing parameters, please ensure all parameters are provided.");
    return;
  }

  try {
    const response = await fetch(`${BASE_URL}/signup/${table}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });
    if (!response?.ok) {
      throw new Error("User registration problem");
    }
    return response.json();
  } catch (error) {
    throw new Error("User registration problem");
  }
};

export const putUsersUpdate = async (infoUser, idUser, tableData, token) => {
  if (!infoUser || !idUser || !tableData || !token) {
    console.warn("Missing parameters, please ensure all parameters are provided.");
    return;
  }

  try {
    const response = await fetch(`${BASE_URL}/Users/${tableData}/${idUser}`, {
      method: "PUT",
      body: JSON.stringify(infoUser),
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Failed to update user, please try again later!");
  }
};

export const postClients = async (data, token) => {
  if (!data || !token) {
    console.warn("Missing parameters, please ensure all parameters are provided.");
    return;
  }

  try {
    const response = await fetch(`${BASE_URL}/Clients`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "x-access-Token": token,
      },
    });
    return response.json();
  } catch (error) {
    throw new Error("Customer creation problem");
  }
};

export const getUsersData = async (token, table) => {
  if (!token || !table) {
    console.warn("Missing parameters, please ensure all parameters are provided.");
    return;
  }

  try {
    const response = await fetch(`${BASE_URL}/Users/${table}`, {
      method: "GET",
      headers: { "x-access-Token": token },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem with user obtaining");
  }
};

export const getUserDataForName = async (token, Username, table) => {
  if (!token || !Username || !table) {
    console.warn("Missing parameters, please ensure all parameters are provided.");
    return;
  }

  try {
    const response = await fetch(`${BASE_URL}/Username/${table}/${Username}`, {
      method: "GET",
      headers: { "x-access-Token": token },
    });
    return response.json();
  } catch (error) {
    throw new Error("Problem with user information");
  }
};

export const deleteUser = async ({ user, table, token }) => {
  try {
    const response = await fetch(
      `${BASE_URL}/Users/${table}/${user.iduser}`,
      {
        method: "DELETE",
        headers: { "x-access-token": token },
      }
    );
    if (!response.ok) {
      throw new Error();
    }

  } catch (error) {
    throw error;
  }
}