import React from 'react'

function SvgIcon ({ src, alt, width = '36px', height = '36px', className = '' }) {
  return (
    <img
        src={src}  // Ruta al archivo SVG
        alt={alt}  // Texto alternativo
        width={width}  // Ancho personalizable
        height={height}  // Alto personalizable
        className={className}  // Clases CSS opcionales
/>
  )
}

export default SvgIcon 