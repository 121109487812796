import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getUsersData, deleteUser } from "../services/api/usersAPI";
import { MODAL_TYPES } from "../types/types_modal";
import { message } from "antd";
import { TABLES } from "../types/types_table";
import { useDispatch } from "react-redux";
import { setLoader } from "../features/loader/loaderSlice";


const defaultUserData = {}
const useCrud = () => {
    const [selectedUser, setSelectedUser] = useState(defaultUserData);
    const [searchTerm, setSearchTerm] = useState('');
    const [modalType, setModalType] = useState('');
    const [enableExternalUsersQuery, setEnableExternalUsersQuery] = useState(false);
    const [enableInternalUsersQuery, setEnableInternalUsersQuery] = useState(false);
    const dispatch = useDispatch();

    const loggedInUserData = JSON.parse(sessionStorage.getItem("userData"));
    const email = loggedInUserData?.user_email;
    const token = loggedInUserData?.token;


    const handleDelete = (user) => {
        setModalType(MODAL_TYPES.DELETE);
        setSelectedUser(user)
    }

    const handleEdit = (user) => {
        setModalType(MODAL_TYPES.EDIT);
        setSelectedUser(user)
    }

    const resetModalData = () => {
        setModalType('');
        setSelectedUser(null)
    }

    const onDeleteConfirm = async (tableName) => {
        try {
            const data = {
                user: selectedUser,
                table: tableName,
                token
            }
            dispatch(setLoader(true));
            await deleteUser(data);
            message.success(`User ${selectedUser?.user_name} deleted successfully`)
            if (tableName === TABLES.ENOVATE) {
                await refetchInternalUsersData();
            } else if (tableName === TABLES.EXTERNAL) {
                await refetchExternalUsersData();
            }

        } catch (err) {
            console.error(err);
            message.error(`Failed to delete user ${selectedUser?.user_name}, please try again later!`)
        } finally {
            resetModalData();
            dispatch(setLoader(false));
        }
    }

    const onCancel = () => {
        resetModalData();
    }

    const onEditConfirm = () => {
        resetModalData();
    }

    const { data: externalUsersData, isLoading, refetch: refetchExternalUsersData } = useQuery({
        queryKey: ['infoUsersExternals', token],
        queryFn: () => getUsersData(token, 'users_externals'),
        enabled: enableExternalUsersQuery,
        onError: () => {
            setEnableExternalUsersQuery(false);
        },
        onSuccess: () => {
            setEnableExternalUsersQuery(false);
        }
    }
    );

    const { data: internalUsersData, isLoadingInternal, refetch: refetchInternalUsersData } = useQuery({
        queryKey: ['infoUsersInternal', token],
        queryFn: () => getUsersData(token, 'users_enovates'),
        enabled: enableInternalUsersQuery,
        onError: () => {
            setEnableInternalUsersQuery(false);
        },
        onSuccess: () => {
            setEnableInternalUsersQuery(false)
        }
    }
    );

    return {
        setEnableExternalUsersQuery,
        setEnableInternalUsersQuery,
        externalUsersData,
        internalUsersData,
        refetchInternalUsersData,
        refetchExternalUsersData,
        isLoading,
        selectedUser,
        setSelectedUser,
        searchTerm,
        setSearchTerm,
        modalType,
        setModalType,
        handleDelete,
        handleEdit,
        onDeleteConfirm,
        onCancel,
        onEditConfirm
    }
}

export default useCrud;