export const DATA_ROLES = {
    ADMIN: 'Admin',
    MODERATOR: 'Moderator',
    USER_ENOVATE: "User Enovate",
    SIMPLE_USER: "Simple User"
}

export const ROLES_TO_REGISTER = [DATA_ROLES.ADMIN, DATA_ROLES.MODERATOR, DATA_ROLES.USER_ENOVATE];
export const ROLES_TO_UPDATE = [DATA_ROLES.ADMIN];

