const externalUserTableColumns = [
    {
        title: 'User ID',
        dataIndex: 'iduser',
        key: 'iduser',
    },
    {
        title: 'User Name',
        dataIndex: 'user_name',
        key: 'user_name',
    },
    {
        title: 'Client',
        dataIndex: 'clients',
        key: 'clients',
    },
];

export default externalUserTableColumns;