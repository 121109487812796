import { useEffect } from 'react';
import { Input, Table } from 'antd';
import { DeleteTwoTone, EditTwoTone, SearchOutlined } from '@ant-design/icons';
import internalUserTableColumns from '../../utils/table/internalUserTableColumns';
import DeleteUserModal from '../../components/modals/DeleteUserModal';
import EditUserModal from '../../components/modals/EditUserModal';
import { MODAL_TYPES } from '../../types/types_modal';
import { USER_TYPES } from '../../types/types_user';
import useCrud from '../../hooks/useCrud';
import { TABLES } from '../../types/types_table';
import NavigationModal from '../../components/modals/NavigationModal';
import { useSelector } from 'react-redux';
import { ROLES_TO_UPDATE } from '../../types/type_roles';

function CrudInternal() {
  const { userData } = useSelector(state => state.configAuth);
  const userRoles = !Array.isArray(userData?.role) ? [userData?.role] : userData.role;
  const isEligibleToUpdateUser = userRoles?.some?.(role => ROLES_TO_UPDATE.includes(role));

  const { internalUsersData,
    setEnableInternalUsersQuery,
    searchTerm,
    setSearchTerm,
    selectedUser,
    handleEdit,
    handleDelete,
    onDeleteConfirm,
    onCancel,
    onEditConfirm,
    setSelectedUser,
    modalType } = useCrud();

  useEffect(() => {
    setEnableInternalUsersQuery(true)
  }, [])

  const columns = [
    ...internalUserTableColumns,
    {
      title: 'Edit',
      dataIndex: 'edit',
      render: (_, record) => {
        return <EditTwoTone onClick={() => handleEdit(record)} />
      }
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      render: (_, record) => {
        return <DeleteTwoTone twoToneColor="#FF0000" onClick={() => handleDelete(record)} />
      }
    },
  ]
  const getFilteredUserData = () => {
    return internalUsersData?.filter?.(user => (user?.user_name?.toLowerCase?.()?.includes(searchTerm?.toLowerCase()) ||
      user?.clients?.toString().toLowerCase().includes(searchTerm?.toLowerCase()) ||
      user?.roles_name?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      user?.iduser?.toString().toLowerCase().includes(searchTerm?.toLowerCase())))
  }
  const userInfo = searchTerm ? getFilteredUserData() : internalUsersData;

  if (!isEligibleToUpdateUser) {
    return <NavigationModal />
  }

  return (
    <div className='h-full w-full bg-white p-6 overflow-y-auto'>
      <div className='user-search my-4'>
        <Input
          placeholder="Search..."
          prefix={<SearchOutlined />}
          size='large'
          className='w-80p md:w-60p lg:w-40p'
          value={searchTerm}
          onChange={
            (e) => setSearchTerm(e.target.value)
          } />
      </div>
      <div className='user-table'>
        <Table
          columns={columns}
          dataSource={userInfo}
          rowKey={record => record?.iduser}
          pagination={{ pageSize: 5 }}
          bordered />
      </div>
      {modalType === MODAL_TYPES.DELETE && (
        <DeleteUserModal
          open
          user={selectedUser}
          onDeleteConfirm={() => onDeleteConfirm(TABLES.ENOVATE)}
          onCancel={onCancel} />)}
      {modalType === MODAL_TYPES.EDIT && (
        <EditUserModal
          open
          user={selectedUser}
          updateUser={setSelectedUser}
          userType={USER_TYPES.MASTER}
          onCancel={onCancel}
          onEditConfirm={onEditConfirm}
          table={TABLES.ENOVATE}
        />)}
    </div>
  )
}

export default CrudInternal