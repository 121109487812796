const internalUserTableColumns = [
    {
        title: 'User ID',
        dataIndex: 'iduser',
        key: 'iduser',
    },
    {
        title: 'User Name',
        dataIndex: 'user_name',
        key: 'user_name',
    },
    {
        title: 'Client',
        dataIndex: 'clients',
        key: 'clients',
        render: (_, record) => {
            return record?.clients?.join(', ')
        },
    },
    {
        title: 'Role',
        dataIndex: 'roles_name',
        key: 'roles_name',
        render: (_, record) => {
            return record?.roles_name?.join(', ')
        }
    },
];

export default internalUserTableColumns;