import React from 'react';
import { Modal } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';

const DeleteUserModal = ({ open = false, user = {}, onDeleteConfirm = () => { }, onCancel = () => { } }) => {
    return (
        <div className='delete-user-modal'>
            <Modal
                okText="Delete"
                okButtonProps={{ danger: true }}
                cancelText="Cancel"
                cancelButtonProps={{ danger: true }}
                title={(
                    <div className='delete-user-modal-header flex justify-center text-[3rem]'>
                        <DeleteTwoTone twoToneColor="red" />
                    </div>
                )}
                centered
                closable={false}
                open={open}
                onOk={onDeleteConfirm}
                onCancel={onCancel}>
                <div className='delete-user-modal-body py-6 flex flex-col items-center text-lg'>
                    <p>Are you sure, you want to delete the user</p>
                    <span><strong>{user?.user_name}</strong></span>
                </div>
            </Modal>
        </div>
    )
}

export default DeleteUserModal