import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthForm } from '../../components/Login/AuthForm';


function Login() {
    const { auth } = useSelector(state => state.configAuth);
    if (auth) {
        return <Navigate to='/' replace />
    }
    return (
        <div className="flex w-full h-screen font-sans bg-no-repeat bg-center bg-cover bg-fd-login justify-start items-center">
            <div className="w-full h-screen flex items-center justify-center lg:items-end lg:justify-start  md:ml-10 lg:ml-20">
                <AuthForm />
            </div>
        </div>
    )
}

export default Login