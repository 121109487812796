import React from 'react';
import { ReactComponent as EnovateLogo } from '../assets/EnovateLogo.svg';
import ProductTileList from '../components/ProductTileList';

const Dashboard = () => {
    return (
        <div className='dashboard-container flex flex-col items-center justify-center h-full overflow-y-auto'>
            <div className='dashboard-main-logo'>
                <EnovateLogo height={40} />
            </div>
            <div className='dashboard-header font-bold text-xl my-6'>
                <p>How can I help you?</p>
            </div>
            <div>
                <ProductTileList />
            </div>
        </div>
    )
}

export default Dashboard